#login-page {
    margin: 0;
    color: #6a6f8c;
    background: #c8c8c8;
    height: 70vh;
    display: flex;

    .login-wrap {
        width: 100%;
        height: 100%;
        margin: auto;
        // max-width: 525px;
        // min-height: 670px;
        position: relative;
        background: url(../../assets/img/bg.jpg) no-repeat center;
        box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .24), 0 17px 50px 0 rgba(0, 0, 0, .19);

        .login-html {
            width: 100%;
            height: 100%;
            position: absolute;
            padding: 30px 70px 50px 70px;
            background: rgba(111, 111, 111, 0.9);

            .ant-tabs-nav .ant-tabs-tab-active {
                color: white;
            }

            .ant-tabs-nav .ant-tabs-tab:hover {
                color: rgba(255, 255, 255, 0.541);
            }

            .ant-tabs .ant-tabs-top-content {
                color: aliceblue;
            }

            .ant-tabs-ink-bar {
                background-color: antiquewhite;
            }

            .white-text {
                div {
                    label {
                        color: white;

                    }
                }
            }

            .inline-form {
                div.ant-col.ant-form-item-control-wrapper {
                    display: inline-block;
                }
            }
        }
    }

}